/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* FreightDesk.ai theme variables */
.fdComponentHeader {
  background-color: var(--ion-color-medium);
  font-weight: bold;
  color: white;
}

.fdComponentHeader ion-label {
  font-size: medium;
  padding-left: 0.5rem;
}

.fdComponentHeader p {
  font-size: x-small;
  padding-left: 2rem;
}

.fdComponentRow {
  // background-color: var(--ion-color-light);
  display: flex;
  align-items: center;
}

.fdRowSeparation {
  margin-top: 0.5rem;
}

.fdComponentLabel {
  background-color: var(--ion-color-light);
  font-weight: bold;
  // color: black;
}

.fdComponentContent {
  background-color: var(--ion-color-light);
  font-weight: normal;
  // color: black;
}

.fdComponentItemsHeader {
  font-weight: bold;
  // color: black;
}

.fdComponentItems {
  font-weight: normal;
  // color: black;
}

.fdComponentLabel ion-label,
.fdComponentContent ion-label,
.fdComponentItems ion-label,
.fdComponentItemsHeader ion-label {
  font-size: small;
  padding-left: 0.5rem;
}

.fdComponentContent ion-input,
.fdComponentItems ion-input {
  font-size: small;
  padding-left: 0.5rem;
  --padding-top: 0rem;
  --padding-bottom: 0rem;
}

.fdComponentContent ion-textarea,
.fdComponentItems ion-textarea {
  font-size: small;
  padding-left: 0.5rem;
  --padding-top: 0.5rem;
}

.fdItemList {
  background-color: white;
}

.fdItemList ion-item-divider {
  background-color: var(--ion-color-medium);
  font-size: small;
  font-weight: bold;
  color: white;
}

.fdItemList ion-item {
  --background: var(--ion-color-light);
  font-size: medium;
}

.fdCheckbox {
  display: flex;
  font-size: small;
  width: 100%;
  max-width: 100%;
  display: block;
  white-space: normal;
  --checkbox-background-checked: var(--ion-color-primary);
  --checkmark-color: white;
}

.fdCheckboxConatainer .fdItemContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ion-color-light);
  --background: var(--ion-color-light);
  --padding-start: 0.5rem;
  --inner-padding-end: 0.5rem;
  border-radius: 4px;
}

.fdInput,
.fdLabel,
.fdSelect {
  background-color: var(--ion-color-light);
  --padding-start: 0.5rem;
  --padding-end: 0.5rem;
  --placeholder-color: var(--ion-color-dark);
  font-size: small;
  font-weight: bold;
}

.invalid-email, .invalid-contactphone, .invalid-numpkgs, .invalid-acctnum {
  background-color: #f8d7da; /* pale red background */
}

.fdContactText {
  background-color: var(--ion-color-light);
  --padding-start: 0.5rem;
  --placeholder-color: var(--ion-color-dark);
  font-size: medium;
}

.fdSendRQTitle {
  font-size: 2rem;
  font-weight: Bold;
}

.fdAddressConfToolbar {
  background-color: var(--ion-color-light);
  font-size: 2rem;
  font-weight: Bold;
}

.fdSendRQButton {
  font-size: 1.2rem;
  font-weight: Bold;
}

.fdSmallDatetime {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fdSmallDatetime ion-datetime {
  --width: 100%;
  --height: 30px;
  --min-height: 30px;
  font-size: small;
  line-height: 1.2;
}

.send-rq-modal-class {
  --width: 90%; /* Set width as a percentage or any desired value */
  --height: 90%; /* Set height as a percentage or any desired value */
  // --min-height: 500px; /* You can also set a minimum height if needed */
  // --max-width: 600px; /* You can also set a maximum width */
  --border-radius: 10px; /* Optional: customize border-radius */
}

.center-text {
  text-align: center;
  //   justify-content: center;
}

.fd-custom-border {
  border-top: 1px solid var(--ion-color-medium);
}

.rq-custom-alert-header {
  font-size: 1.5rem !important; /* Adjust the size as needed */
}

.rq-custom-alert-message {
  font-size: 1.2rem !important; /* Adjust the size as needed */
}

.rq-custom-alert .alert-title {
  @extend .rq-custom-alert-header;
}

.rq-custom-alert .alert-message {
  @extend .rq-custom-alert-message;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Style for the custom alert */
// .fd-custom-alert {
//   background-color: white;
// }

/* Header styles */
.fd-custom-alert .alert-head {
  background-color: var(--ion-color-light); /* Light gray background */
  color: red; /* Bold red text */
  font-weight: bold;
  font-size: 1.4rem; /* Make header text larger */
  padding: 12px; /* Add some padding */
}

/* Message styles */
.fd-custom-alert .alert-message {
  font-size: 1.2rem; /* Make the message text larger */
  color: #333; /* Dark gray message text */
}

/* Button styles */
.fd-custom-alert .alert-button {
  --background: black; /* Black background for the button */
  --color: white; /* White text */
  --border-radius: 20px; /* Rounded button */
  font-size: 1.2rem; /* Larger button text */
  padding: 10px 20px; /* Adjust padding for a better button size */
}

// .address-alert-modal {
//   --height: auto;
//   --min-height: 10vh;
//   --max-height: 80vh;
//   height: fit-content;
// }